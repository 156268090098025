/* Copyright 2013 - 2022 Waiterio LLC */
import { useQuery } from '@tanstack/react-query'
import getProject from '@imagelato/client/getProject.js'

export default function useProject(projectIdOrName, organizationId) {
  return useQuery({
    queryKey: ['projects', projectIdOrName],
    queryFn: () => getProject(projectIdOrName, organizationId),
  })
}
