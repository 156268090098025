/* Copyright 2013 - 2022 Waiterio LLC */
import React from 'react'

const IconDelete = props => {
  const { className, width = 24, height = 24, onClick, style } = props
  const dataTestId = props['data-testid'] || 'icon-delete'

  return (
    <svg
      className={className}
      style={style}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      onClick={onClick}
      data-testid={dataTestId}
    >
      <path d="M6 19.008c0 1.104 0.888 1.992 1.992 1.992h8.016c1.104 0 1.992-0.888 1.992-1.992v-12h-12v12zM19.008 4.008h-3.504l-1.008-1.008h-4.992l-1.008 1.008h-3.504v1.992h14.016v-1.992z" />
    </svg>
  )
}

export default IconDelete
