/* Copyright 2013 - 2022 Waiterio LLC */
import React from 'react'

export function getRoute(path, Component) {
  let route

  if (path && Component) {
    if (typeof path === 'string') {
      route = {
        path,
        element: <Component />,
      }
    } else if (typeof path === 'object') {
      route = {
        path: path.path,
        element: <Component {...path.props} />,
      }
    }
  }

  return route
}

function getRoutes({ context, exportName }) {
  // console.log('getRoutes start')
  let routes = []
  const keys = context.keys()
  for (let k = 0; k < keys.length; k += 1) {
    let key = keys[k]
    let module = context(key)
    let Component

    if (!exportName) {
      Component = module.default ? module.default : module
    } else {
      Component = module[exportName]
    }

    let { path, paths } = module

    if (path) {
      routes.push(getRoute(path, Component))
    } else if (paths) {
      paths.forEach(path => {
        routes.push(getRoute(path, Component))
      })
    } else {
      console.error(`${key} is missing export path or export paths`)
    }
  }
  // console.log('getRoutes end')
  return routes.filter(_ => _)
}

export default getRoutes
