/* Copyright 2013 - 2022 Waiterio LLC */
/** @jsx jsx */
import React, { useEffect, useState } from 'react'
import { css, jsx } from '@emotion/react'
import { useParams } from 'react-router-dom'
import useProject from '@imagelato/react/useProject.js'
import useProjectMutation from '@imagelato/react/useProjectMutation.js'
import card from '@stiloso/styles/card.js'
import label from '@stiloso/styles/label.js'
import input from '@stiloso/styles/input.js'
import Button from '@stiloso/components/Button.js'
import section from '@stiloso/styles/section.js'
import sectionTitle from '@stiloso/styles/sectionTitle.js'
import { useTranslation } from '@multilocale/react/index.js'
import Layout from '../components/Layout.js'
import useLoggedInSession from '../session/useLoggedInSession.js'

export const paths = ['/projects/:projectName']

const ProjectPage = () => {
  const { projectName } = useParams()
  const { t } = useTranslation()
  useLoggedInSession()
  const { data: project } = useProject(projectName)
  const { mutate: mutateProject } = useProjectMutation()
  let [name, setName] = useState('')
  let [repo, setRepo] = useState('')
  let [directory, setDirectory] = useState('')
  let [output, setOutput] = useState('')

  useEffect(() => {
    if (project) {
      setName(project.name)
      setRepo(project.repo)
      setDirectory(project.directory)
      setOutput(project.output)
    }
  }, [project])

  const changeName = event => {
    let name = event.target.value

    name = name.toLowerCase()
    name = name.replaceAll(' ', '-')

    setName(name)
  }

  const saveProject = async event => {
    event.preventDefault()

    try {
      await mutateProject({
        ...project,
        name,
        directory,
        output,
        repo,
      })
    } catch (error) {
      // TO DO
    }
  }

  return (
    <Layout>
      {project && (
        <div css={section}>
          <div css={sectionTitle}>{t('Project')}</div>
          <form onSubmit={saveProject} css={card}>
            <label htmlFor="idInput" css={label}>
              {t('id')}
            </label>
            <input id="idInput" css={input} value={project._id} disabled />
            <label htmlFor="nameInput" css={label}>
              {t('name')}
            </label>
            <input
              id="nameInput"
              css={input}
              value={name}
              onChange={changeName}
              required
              minLength="1"
              autoFocus
            />
            <Button type="submit" css={{ marginTop: 16 }} fullWidth>
              {t('Save project')}
            </Button>
          </form>
        </div>
      )}
    </Layout>
  )
}

export default ProjectPage
