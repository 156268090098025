/* Copyright 2013 - 2022 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { css, jsx } from '@emotion/react'
import useProject from '@imagelato/react/useProject.js'
import useTemplates from '@imagelato/react/useTemplates.js'
import card from '@stiloso/styles/card.js'
import clickable from '@stiloso/styles/clickable.js'
import section from '@stiloso/styles/section.js'
import AppBar from '@stiloso/components/AppBar.js'
import AppBarButton from '@stiloso/components/AppBarButton.js'
import AppBarTitle from '@stiloso/components/AppBarTitle.js'
import { useTranslation } from '@multilocale/react/index.js'
import Layout from '../components/Layout.js'
import useLoggedInSession from '../session/useLoggedInSession.js'

let templateCard = css([
  clickable,
  card,
  {
    lineHeight: '48px',
    width: '100%',
    marginTop: 16,
    padding: '0 16',
  },
])

export const paths = ['/projects/:projectName/templates']

const TemplatesPage = () => {
  useLoggedInSession()
  const { projectName } = useParams()
  const { t } = useTranslation()
  let { data: project } = useProject(projectName)
  let { data: templates } = useTemplates({ projectId: project._id })

  templates = templates?.sort((a, b) => (a.name > b.name ? 1 : -1))

  return (
    <Layout>
      <div css={section}>
        <AppBar>
          <AppBarTitle>{t('Templates')}</AppBarTitle>
          <div css={{ flexGrow: 1 }} />
          <AppBarButton
            label={t('New template')}
            to={`/projects/${projectName}/templates/new`}
          />
        </AppBar>
        <div
          css={{
            padding: 16,
            display: 'flex',
            flexWrap: 'wrap',
          }}
        >
          {templates?.map(({ name }) => (
            <Link
              key={name}
              css={templateCard}
              to={`/projects/${projectName}/templates/${name}`}
            >
              {name}
            </Link>
          ))}
        </div>
      </div>
    </Layout>
  )
}

export default TemplatesPage
