/* Copyright 2013 - 2022 Waiterio LLC */
import setCookie from '@monorepo/shared/setCookie.js'
import {
  setAccessTokenForImagelatoClient,
  setAccessTokenCallbackForImagelatoClient,
} from '@imagelato/client/accessToken.js'
import { setRefreshTokenForImagelatoClient } from '@imagelato/client/refreshToken.js'
import getAccessTokenPayload from './getAccessTokenPayload.js'
import setAccessToken from './setAccessToken.js'
import setRefreshToken from './setRefreshToken.js'
import identify from '../identify.js'

export default async function storeNewSession({ accessToken, refreshToken }) {
  try {
    setAccessToken(accessToken)
    setRefreshToken(refreshToken)

    setAccessTokenForImagelatoClient(accessToken, setAccessToken)
    setRefreshTokenForImagelatoClient(refreshToken, setRefreshToken)
    setAccessTokenCallbackForImagelatoClient(setAccessToken)

    let accessTokenPayload = getAccessTokenPayload()

    setCookie('globoUserId', accessTokenPayload?.metadata?.userId)
    setCookie('globoSignature', accessTokenPayload?.metadata?.globoSignature)
    identify()

    return true
  } catch (error) {
    console.error('error', error)
    throw error
  }
}
