/* Copyright 2013 - 2022 Waiterio LLC */
/** @jsx jsx */
import React, { useState } from 'react'
import { jsx, css } from '@emotion/react'
import { useNavigate, Link } from 'react-router-dom'
import useProjects from '@imagelato/react/useProjects.js'
import useUpdateProjectMutation from '@imagelato/react/useUpdateProjectMutation.js'
import { useTranslation } from '@multilocale/react/index.js'
import IconBrand from '@imagelato/shared/IconBrand.js'
import getBrowserLanguage from '@monorepo/shared/getBrowserLanguage.js'
import languages from '@monorepo/shared/languages.js'
import Button from '@stiloso/components/Button.js'
import label from '@stiloso/styles/label.js'
import input from '@stiloso/styles/input.js'
import LanguageAutocomplete from '@stiloso/components/LanguageAutocomplete.js'
import useLoggedInSession from '../session/useLoggedInSession.js'

export const paths = ['/setup-project']

const SetupProjectPage = () => {
  useLoggedInSession()
  const navigate = useNavigate()
  const language = getBrowserLanguage()
  const { data: projects } = useProjects()
  const [project] = projects
  const { mutate, isLoading, error, reset } = useUpdateProjectMutation()
  const [name, setName] = useState(project.name || '')
  const [selectedLanguage, setSelectedLanguage] = useState(language)
  const { t } = useTranslation()

  const languages_ = Object.keys(languages).map(languageCode => ({
    code: languageCode,
    name: languages[languageCode],
  }))

  const changeName = event => {
    let name = event.target.value

    name = name.toLowerCase()
    name = name.replaceAll(' ', '-')

    setName(name)
  }

  const setupProject = async event => {
    event.preventDefault()

    if (error) {
      reset()
    }

    const defaultLocale = selectedLanguage
    const locales = [defaultLocale]

    let creationTime = new Date().toISOString()
    let lastEditTime = creationTime

    let updatedProject = {
      ...project,
      name: name.replace(/^-+|-+$/g, ''),
      defaultLocale,
      locales,
      creationTime,
      lastEditTime,
    }

    mutate(updatedProject, {
      onSuccess: project => {
        navigate(`/projects/${project.name}/templates`)
      },
    })
  }

  return (
    <div
      css={{
        height: '100%',
        padding: '48px 0px',
        backgroundColor: 'var(--color-primary)',
      }}
    >
      <div
        css={{
          maxWidth: 500,
          marginLeft: 'auto',
          marginRight: 'auto',
          padding: '0px 16px',
        }}
      >
        <Link to="/" css={{ padding: '0px 16px' }}>
          <IconBrand light />
        </Link>

        <div
          css={{
            margin: '16px 0px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div css={{ width: '100%', maxWidth: 480 }}>
            <form
              onSubmit={setupProject}
              css={{
                background: 'var(--color-background)',
                padding: '16px 32px',
                borderRadius: 8,
              }}
            >
              <div>
                <label htmlFor="name" css={label}>
                  {t('name')}
                </label>
                <input
                  id="name"
                  css={input}
                  value={name}
                  placeholder={t('Your project name')}
                  onChange={changeName}
                  required
                  autoFocus
                />
              </div>
              <LanguageAutocomplete
                inputLabel="Default language"
                languages={languages_}
                selectedLanguage={selectedLanguage}
                onSelectLanguage={setSelectedLanguage}
              />
              <Button
                type="submit"
                css={{ margin: '32px 0px' }}
                isLoading={isLoading}
                fullWidth
              >
                {t('Start uploading images')}
              </Button>
              {error && (
                <div
                  css={{
                    textAlign: 'center',
                    lineHeight: '24px',
                    marginTop: 16,
                    color: 'red',
                  }}
                >
                  {error.message}
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SetupProjectPage
