/* Copyright 2013 - 2022 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'

const SIZES = {
  small: '400px',
  medium: '512px',
  large: '624px',
}

export const modalOuter = css({
  display: 'flex',
  alignItems: 'center',
  alignContent: 'center',
  justifyContent: 'center',
  position: 'fixed',
  transform: 'translate(0,0)',
  width: 'auto',
  left: 0,
  right: 0,
  height: 'auto',
  top: 0,
  bottom: 0,
  zIndex: 9990,
  background: '#transparent',
  padding: '0 16px',
})

export const modal = css({
  boxSizing: 'border-box',
  height: 'auto',
  maxHeight: '100%',
  overflowX: 'hidden',
  overflowY: 'auto',
  position: 'relative',
  zIndex: 9999,
  background: '#fefefe',
  borderRadius: 8,
})

export const backdrop = css({
  display: 'block',
  position: 'absolute',
  width: 'auto',
  left: 0,
  right: 0,
  height: 'auto',
  top: 0,
  bottom: 0,
  boxSizing:
    'border-box; -moz-box-sizing: border-box; -webkit-box-sizing: border-box; -o-box-sizing: border-box',
  zIndex: 9995 /* display between modal container and modal window */,
  backgroundColor: ['rgb(0,0,0)', 'rgba(0,0,0,0.4)'],
})

const Modal = ({ children, close, size = 'medium' }) => {
  const _close = event => {
    if (event) {
      event.preventDefault()
    }

    if (close) {
      close()
    }
  }

  return (
    <div css={modalOuter}>
      <div css={[modal, { width: SIZES[size] }]}>{children}</div>
      <div css={backdrop} onClick={_close} data-testid="close-modal" />
    </div>
  )
}

export default Modal
