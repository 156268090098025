/* Copyright 2013 - 2022 Waiterio LLC */
import get from './get.js'

export default function getBatches(parameters) {
  let url = 'batches'

  url += '?' + new URLSearchParams(parameters).toString()

  return get({ url }).catch(error => {
    throw new Error(`Couldn't get batches \n${error}`)
  })
}
