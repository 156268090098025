/* Copyright 2013 - 2022 Waiterio LLC */
import getImagelatoApiUrl from '@monorepo/env/getImagelatoApiUrl.js'

export default (email, password) =>
  fetch(getImagelatoApiUrl() + '/api/login', {
    method: 'POST',
    headers: {
      Authorization: `Basic ${btoa(email + ':' + password)}`,
    },
  }).then(response => {
    if (!response.ok) {
      return response.json().then(error => {
        throw error
      })
    } else {
      return response.json()
    }
  })
