/* Copyright 2013 - 2022 Waiterio LLC */
/** @jsx jsx */
import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { jsx } from '@emotion/react'
import useProject from '@imagelato/react/useProject.js'
import getBatches from '@imagelato/client/getBatches.js'
import AppBar from '@stiloso/components/AppBar.js'
import AppBarButton from '@stiloso/components/AppBarButton.js'
import AppBarTitle from '@stiloso/components/AppBarTitle.js'
import { useTranslation } from '@multilocale/react/index.js'
import Layout from '../components/Layout.js'
import useLoggedInSession from '../session/useLoggedInSession.js'

export const paths = ['/projects/:projectName/images']

const ImagesPage = () => {
  useLoggedInSession()
  const { projectName } = useParams()
  const { data: project } = useProject(projectName)
  const { t } = useTranslation()
  const [images, setImages] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()

  useEffect(() => {
    async function fetchImageUrls() {
      try {
        setLoading(true)

        const batches = await getBatches({
          projectId: project._id,
        })

        const images = batches.map(batch => ({
          url: batch.url,
          slug: batch.slug,
        }))

        setImages(images)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        setError(error.message)
      }
    }

    fetchImageUrls()
  }, [project._id])

  return (
    <Layout>
      <AppBar>
        <AppBarTitle>{t('Images')}</AppBarTitle>
        <div css={{ flexGrow: 1 }} />
        <AppBarButton
          label={t('New image')}
          to={`/projects/${projectName}/images/new`}
        />
      </AppBar>
      {loading && <div className="loader" />}
      {error && <div>{error}</div>}
      <div css={{ padding: 8 }}>
        {images.map(({ url, slug }) => (
          <div key={url} css={{ padding: 8, display: 'flex' }}>
            <img css={{ width: 120 }} src={url} />
            <a
              css={{ padding: 8 }}
              href={url}
              target="_blank"
              rel="noreferrer noopener"
            >
              {slug}
            </a>
          </div>
        ))}
      </div>
    </Layout>
  )
}

export default ImagesPage
