/* Copyright 2013 - 2022 Waiterio LLC */
import countryData from 'country-data'

export default function getLanguageNameByCode(languageCode) {
  if (languageCode === '??') {
    return 'Unknown'
  } else if (languageCode === 'ca') {
    return 'Catalan'
  } else if (languageCode === 'el') {
    return 'Greek'
  } else if (languageCode === 'km') {
    return 'Khmer'
  } else if (languageCode === 'nl') {
    return 'Dutch'
  } else if (
    countryData &&
    countryData.languages &&
    countryData.languages[languageCode] &&
    countryData.languages[languageCode].name
  ) {
    return countryData.languages[languageCode].name
  } else {
    return languageCode
  }
}
