/* Copyright 2013 - 2022 Waiterio LLC */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'

const Fallback = () => (
  <div style={{ height: '100%' }}>
    <div className="loader" />
  </div>
)

export default Fallback
