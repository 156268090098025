/* Copyright 2013 - 2022 Waiterio LLC */
import { css } from '@emotion/react'
import rotationString from '@stiloso/styles/rotationString.js'

export default css`
  label: spinner;
  margin: 0px auto;
  display: block;

  border-radius: 50%;
  width: 8em;
  height: 8em;

  font-size: 4px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid var(--color-background);
  border-right: 1.1em solid var(--color-background);
  border-bottom: 1.1em solid var(--color-background);
  border-left: 1.1em solid var(--color-primary-dark, gray);
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: rotation 1.1s infinite linear;
  animation: rotation 1.1s infinite linear;
  ${rotationString};
`
