/* Copyright 2013 - 2022 Waiterio LLC */
import fetch from '@monorepo/fetch/index.js'
import getImagelatoApiUrl from '@monorepo/env/getImagelatoApiUrl.js'

export default ({ email, password, language }) =>
  fetch({
    url: getImagelatoApiUrl() + '/api/signup',
    method: 'POST',
    body: { language },
    headers: {
      Authorization: `Basic ${btoa(email + ':' + password)}`,
    },
  })
