/* Copyright 2013 - 2022 Waiterio LLC */
import React from 'react'

const IconAdd = props => {
  const { className, width = 24, height = 24, onClick, style } = props
  const dataTestId = props['data-testid'] || 'icon-dashboard'

  return (
    <svg
      className={className}
      style={style}
      width={width}
      height={height}
      viewBox="0 0 48 48"
      onClick={onClick}
      data-testid={dataTestId}
    >
      <path d="M25.5 19.5V6H42V19.5ZM6 25.5V6H22.5V25.5ZM25.5 42V22.5H42V42ZM6 42V28.5H22.5V42ZM9 22.5H19.5V9H9ZM28.5 39H39V25.5H28.5ZM28.5 16.5H39V9H28.5ZM9 39H19.5V31.5H9ZM19.5 22.5ZM28.5 16.5ZM28.5 25.5ZM19.5 31.5Z" />
    </svg>
  )
}

export default IconAdd
