/* Copyright 2013 - 2022 Waiterio LLC */
import React from 'react'

const IconAdd = props => {
  const { className, width = 24, height = 24, onClick, style } = props
  const dataTestId = props['data-testid'] || 'icon-add'

  return (
    <svg
      className={className}
      style={style}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      onClick={onClick}
      data-testid={dataTestId}
    >
      <path d="M19.008 13.008h-6v6h-2.016v-6h-6v-2.016h6v-6h2.016v6h6v2.016z" />
    </svg>
  )
}

export default IconAdd
