/* Copyright 2013 - 2022 Waiterio LLC */
let PLUS_ONLY = /\+.*$/
let PLUS_AND_DOT = /\.|\+.*$/g

let normalizeableProviders = {
  'gmail.com': {
    cut: PLUS_AND_DOT,
  },
  'googlemail.com': {
    cut: PLUS_AND_DOT,
    aliasOf: 'gmail.com',
  },
  'hotmail.com': {
    cut: PLUS_ONLY,
  },
  'hotmail.it': {
    cut: PLUS_ONLY,
  },
  'live.com': {
    cut: PLUS_AND_DOT,
  },
  'live.it': {
    cut: PLUS_AND_DOT,
  },
  'outlook.com': {
    cut: PLUS_ONLY,
  },
}

export default function normalizeEmail(email) {
  if (typeof email !== 'string') {
    throw new TypeError('normalizeEmail expects a string')
  }

  email = email.trim().toLowerCase()
  let parts = email.split(/@/)

  if (parts.length !== 2) {
    return email
  }

  let username = parts[0]
  let domain = parts[1]

  if (normalizeableProviders[domain]) {
    if (normalizeableProviders[domain].cut) {
      username = username.replace(normalizeableProviders[domain].cut, '')
    }
    if (normalizeableProviders[domain].aliasOf) {
      domain = normalizeableProviders[domain].aliasOf
    }
  }

  return username + '@' + domain
}
