/* Copyright 2013 - 2022 Waiterio LLC */
import {
  setAccessTokenForImagelatoClient,
  setAccessTokenCallbackForImagelatoClient,
} from '@imagelato/client/accessToken.js'
import { setRefreshTokenForImagelatoClient } from '@imagelato/client/refreshToken.js'
import getAccessToken from './getAccessToken.js'
import getRefreshToken from './getRefreshToken.js'
import setAccessToken from './setAccessToken.js'

export default async function rehydrateSession() {
  const accessToken = getAccessToken()
  const refreshToken = getRefreshToken()

  setAccessTokenForImagelatoClient(accessToken)
  setRefreshTokenForImagelatoClient(refreshToken)
  setAccessTokenCallbackForImagelatoClient(setAccessToken)
}
