/* Copyright 2013 - 2022 Waiterio LLC */
/* eslint max-len: 0 */

import React from 'react'

const IconSettings = props => {
  const { className, width = 24, height = 24, onClick, style } = props
  const dataTestId = props['data-testid'] || 'icon-settings'

  return (
    <svg
      className={className}
      style={style}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      onClick={onClick}
      data-testid={dataTestId}
    >
      <path d="M19.44 12.984c0.024-0.336 0.072-0.648 0.072-0.984s-0.048-0.648-0.072-0.984l2.112-1.656c0.192-0.144 0.24-0.408 0.12-0.624l-2.016-3.48c-0.12-0.216-0.384-0.288-0.6-0.216l-2.496 1.008c-0.504-0.384-1.080-0.72-1.68-0.984l-0.384-2.64c-0.048-0.24-0.24-0.432-0.504-0.432h-3.984c-0.264 0-0.456 0.192-0.504 0.432l-0.384 2.64c-0.6 0.264-1.152 0.6-1.68 0.984l-2.496-1.008c-0.216-0.072-0.48 0-0.6 0.216l-1.992 3.48c-0.144 0.216-0.072 0.48 0.12 0.624l2.088 1.656c-0.024 0.336-0.048 0.648-0.048 0.984s0.024 0.648 0.048 0.984l-2.088 1.656c-0.192 0.144-0.264 0.408-0.12 0.624l1.992 3.48c0.12 0.216 0.384 0.288 0.6 0.216l2.496-1.008c0.528 0.384 1.080 0.72 1.68 0.984l0.384 2.64c0.048 0.24 0.24 0.432 0.504 0.432h3.984c0.264 0 0.456-0.192 0.504-0.432l0.384-2.64c0.6-0.264 1.176-0.6 1.68-0.984l2.496 1.008c0.216 0.072 0.48 0 0.6-0.216l2.016-3.48c0.12-0.216 0.048-0.48-0.12-0.624l-2.112-1.656zM12 15.504c-1.944 0-3.504-1.56-3.504-3.504s1.56-3.504 3.504-3.504 3.504 1.56 3.504 3.504-1.56 3.504-3.504 3.504z" />
    </svg>
  )
}

export default IconSettings
