/* Copyright 2013 - 2022 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { useParams } from 'react-router-dom'
import { css, jsx } from '@emotion/react'
import useProjects from '@imagelato/react/useProjects.js'
import useUser from '@imagelato/react/useUser.js'
import Drawer from '@stiloso/components/Drawer.js'
import DrawerButton from '@stiloso/components/DrawerButton.js'
import DrawerTitle from '@stiloso/components/DrawerTitle.js'
import { useTranslation } from '@multilocale/react/index.js'
import IconDashboard from '@stiloso/icons/IconDashboard.js'
import IconFolder from '@stiloso/icons/IconFolder.js'
import IconImage from '@stiloso/icons/IconImage.js'
import IconPowerOff from '@stiloso/icons/IconPowerOff.js'
import IconSettings from '@stiloso/icons/IconSettings.js'
import IconUser from '@stiloso/icons/IconUser.js'
import IconUsers from '@stiloso/icons/IconUsers.js'
import getUserId from '../session/getUserId.js'

const drawerButtons = [
  {
    label: 'Projects',
    to: '/projects',
    Icon: IconFolder,
  },
  {
    label: 'Images',
    to: '/projects/{projectName}/images',
    Icon: IconImage,
  },
  {
    label: 'Team',
    to: '/team',
    Icon: IconUsers,
  },
  {
    label: 'Templates',
    to: '/projects/{projectName}/templates',
    Icon: IconDashboard,
  },
  {
    label: 'Settings',
    to: '/projects/{projectName}',
    Icon: IconSettings,
  },
]

const Layout = ({ children }) => {
  const { t } = useTranslation()
  let { data: projects } = useProjects()
  const { data: user } = useUser(getUserId())
  let { projectName } = useParams()
  projects = projects?.sort((a, b) =>
    a.creationTime > b.creationTime ? 1 : -1,
  )

  let pathname = ''

  if (typeof window !== 'undefined') {
    pathname = window.location.pathname
  }

  projectName = projectName || projects?.[0]?.name

  let fullName = ''

  if (user.firstName && user.lastName) {
    fullName = `${user.firstName} ${user.lastName}`
  }

  return (
    <div
      css={{
        display: 'grid',
        height: '100%',
        gridTemplateColumns: '200px auto',
        gridTemplateAreas: `
          'drawer children'
        `,
      }}
    >
      <Drawer>
        <DrawerTitle>{t('Imagelato')}</DrawerTitle>
        {drawerButtons.map(({ label, to, Icon }) => (
          <DrawerButton
            key={to}
            label={t(label)}
            to={to.replace('{projectName}', projectName)}
            Icon={Icon}
            selected={pathname.endsWith(
              to.replace('{projectName}', projectName),
            )}
          />
        ))}
        <div css={{ flexGrow: 1 }} />
        <DrawerButton
          key="/account"
          label={fullName || t('Account')}
          to="/account"
          Icon={IconUser}
          avatarUrl={user.avatarUrl}
        />
        <DrawerButton
          key="/logout"
          label={t('Logout')}
          to="/logout"
          Icon={IconPowerOff}
        />
      </Drawer>
      <div
        css={{
          gridArea: 'children',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          width: '100%',
          background: 'var(--color-background)',
        }}
      >
        {children}
      </div>
    </div>
  )
}

export default Layout
