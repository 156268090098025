/* Copyright 2013 - 2022 Waiterio LLC */
import getAccessTokenPayload from './getAccessTokenPayload.js'

export default function getUserId() {
  let userId
  let payload = getAccessTokenPayload()

  if (payload) {
    userId = payload?.metadata?.userId
  }

  return userId
}
