/* Copyright 2013 - 2022 Waiterio LLC */
import localStorage from 'localStorage'
import {
  setAccessTokenForImagelatoClient,
  setAccessTokenCallbackForImagelatoClient,
} from '@imagelato/client/accessToken.js'
import { setRefreshTokenForImagelatoClient } from '@imagelato/client/refreshToken.js'
import IS_BROWSER from '@monorepo/env/IS_BROWSER.js'
import clearCookies from '@monorepo/shared/clearCookies.js'

export default function clearSession() {
  if (IS_BROWSER) {
    localStorage.clear()

    setAccessTokenCallbackForImagelatoClient(null)

    setAccessTokenForImagelatoClient(null)
    setRefreshTokenForImagelatoClient(null)

    clearCookies()
  }
}
