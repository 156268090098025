/* Copyright 2013 - 2022 Waiterio LLC */
/** @jsx jsx */
import React, { useState } from 'react'
import { jsx } from '@emotion/react'
import useUser from '@imagelato/react/useUser.js'
import useUserMutation from '@imagelato/react/useUserMutation.js'
import useTemplate from '@imagelato/react/useTemplate.js'
import useAddImageMutation from '@imagelato/react/useAddImageMutation.js'
import section from '@stiloso/styles/section.js'
import sectionTitle from '@stiloso/styles/sectionTitle.js'
import getImageFromEvent from '@monorepo/shared/getImageFromEvent.js'
import { useTranslation } from '@multilocale/react/index.js'
import getBrowserLanguage from '@monorepo/shared/getBrowserLanguage.js'
import languages from '@stiloso/shared/languages.js'
import card from '@stiloso/styles/card.js'
import LanguageAutocomplete from '@stiloso/components/LanguageAutocomplete.js'
import ImageGraceful from '@stiloso/components/ImageGraceful.js'
import Button from '@stiloso/components/Button.js'
import IconDelete from '@stiloso/icons/IconDelete.js'
import label from '@stiloso/styles/label.js'
import input from '@stiloso/styles/input.js'
import UploadImageComponent from '@stiloso/components/UploadImage.js'
import Layout from '../components/Layout.js'
import getUserId from '../session/getUserId.js'
import useLoggedInSession from '../session/useLoggedInSession.js'

export const paths = ['/account']

const AccountPage = () => {
  useLoggedInSession()
  const { t } = useTranslation()
  const userMutation = useUserMutation()
  const { data: user } = useUser(getUserId())
  const { data: template } = useTemplate('02b10748225c77796f51baeb')
  const addImageMutation = useAddImageMutation()
  const { language } = user
  const browserLanguage = getBrowserLanguage()
  const [selectedLanguage, setSelectedLanguage] = useState(
    language || browserLanguage,
  )

  const languages_ = Object.keys(languages).map(languageCode => ({
    code: languageCode,
    name: languages[languageCode],
  }))

  const changeUserSettings = event => {
    event.preventDefault()

    if (userMutation.error) {
      userMutation.reset()
    }

    const formData = new FormData(event.target)
    const firstName = formData.get('firstName')
    const lastName = formData.get('lastName')

    const updatedUser = {
      ...user,
      firstName,
      lastName,
      language: selectedLanguage,
      lastEditTime: new Date().getTime(),
    }

    userMutation.mutate(updatedUser)
  }

  const deleteUserAvatar = () => {
    const userWithoutAvatar = {
      ...user,
      avatarUrl: null,
    }

    userMutation.mutate(userWithoutAvatar)
  }

  const onClickUploadAvatar = () => {
    document.getElementById('uploadAvatarInput').click()
  }

  const uploadAvatar = async event => {
    const { sizes, formats } = template
    let imageFile = await getImageFromEvent(event)

    addImageMutation.mutate(
      {
        slug: 'user-avatar',
        template: 'user-avatar',
        file: imageFile,
        formats,
        sizes,
        projectId: template.projectId,
      },
      {
        onSuccess: data => {
          const { url: avatarUrl } = data

          const updatedUser = {
            ...user,
            avatarUrl,
          }

          userMutation.mutate(updatedUser)
        },
      },
    )
  }

  return (
    <Layout>
      <div css={section}>
        <div css={sectionTitle}>{t('Account')}</div>
        <form onSubmit={changeUserSettings} css={card}>
          <div>
            <label htmlFor="email" css={label}>
              {t('email')}
            </label>
            <input id="email" css={input} value={user.email} readOnly />
          </div>
          <div>
            <label htmlFor="firstName" css={label}>
              {t('First name')}
            </label>
            <input
              id="firstName"
              name="firstName"
              css={input}
              defaultValue={user.firstName}
            />
          </div>
          <div>
            <label htmlFor="lastName" css={label}>
              {t('Last name')}
            </label>
            <input
              id="lastName"
              name="lastName"
              css={input}
              defaultValue={user.lastName}
            />
          </div>
          <LanguageAutocomplete
            inputLabel="UI language"
            languages={languages_}
            selectedLanguage={selectedLanguage}
            onSelectLanguage={setSelectedLanguage}
          />
          <div css={{ width: 140 }}>
            <label css={label}>{t('Upload avatar')}</label>
            {user.avatarUrl && (
              <div css={{ position: 'relative', width: 128 }}>
                <ImageGraceful
                  css={{
                    aspectRatio: '1 / 1',
                    height: 128,
                    borderRadius: 1000,
                  }}
                  retry={{
                    count: 10,
                    delay: 1,
                    accumulate: false,
                  }}
                  src={user.avatarUrl.replace('.jpg', '-256w.webp')}
                  fallbackSrc={user.avatarUrl.replace('.jpg', '-256w.jpg')}
                />

                <div
                  css={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    padding: 4,
                    borderRadius: 4,
                    backgroundColor: 'var(--color-primary)',
                    cursor: 'pointer',
                    ':hover': {
                      backgroundColor: 'var(--color-primary-light)',
                    },
                    ':active': {
                      backgroundColor: 'var(--color-primary-dark)',
                    },
                  }}
                  onClick={deleteUserAvatar}
                >
                  <IconDelete css={{ fill: 'white' }} />
                </div>
              </div>
            )}
            {!user.avatarUrl && (
              <UploadImageComponent
                inputId="uploadAvatarInput"
                onClickUploadInput={onClickUploadAvatar}
                uploadImage={uploadAvatar}
                isPending={addImageMutation.isPending}
                styles={{ borderRadius: 1000 }}
              />
            )}
          </div>
          <Button
            type="submit"
            css={{ marginTop: 16 }}
            isLoading={userMutation.isPending}
            fullWidth
          >
            {t('Save')}
          </Button>
        </form>
        {userMutation.error && (
          <div css={{ padding: 16, color: 'var(--color-red' }}>
            {userMutation.error.message}
          </div>
        )}
      </div>
    </Layout>
  )
}

export default AccountPage
