/* Copyright 2013 - 2022 Waiterio LLC */
import { useQuery } from '@tanstack/react-query'
import getTemplates from '@imagelato/client/getTemplates.js'

export default function useTemplates(parameters) {
  return useQuery({
    queryKey: ['templates', { ...parameters }],
    queryFn: () => getTemplates(parameters),
  })
}
