/* Copyright 2013 - 2022 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import IconAdd from '@stiloso/icons/IconAdd.js'
import Button from './Button.js'

const AppBarButton = ({ label, to, onClick, appearance, Icon }) => {
  Icon = Icon || IconAdd
  let iconColor = appearance === 'secondary' ? 'var(--color-base)' : 'white'

  return (
    <Button
      appearance={appearance}
      to={to}
      onClick={onClick}
      css={{ margin: 'auto' }}
    >
      <Icon
        css={{
          fill: iconColor,
          height: 24,
          width: 24,
          marginRight: 4,
          verticalAlign: 'middle',
        }}
      />
      {label}
    </Button>
  )
}

export default AppBarButton
