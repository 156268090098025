/* Copyright 2013 - 2022 Waiterio LLC */
/** @jsx jsx */
import React, { useEffect, useState } from 'react'
import { css, jsx } from '@emotion/react'
import { useNavigate, useParams } from 'react-router-dom'
import addTemplate from '@imagelato/client/addTemplate.js'
import updateTemplate from '@imagelato/client/updateTemplate.js'
import useProject from '@imagelato/react/useProject.js'
import useTemplate from '@imagelato/react/useTemplate.js'
import uuid from '@monorepo/shared/uuid.js'
import card from '@stiloso/styles/card.js'
import clickable from '@stiloso/styles/clickable.js'
import label from '@stiloso/styles/label.js'
import input from '@stiloso/styles/input.js'
import Button from '@stiloso/components/Button.js'
import section from '@stiloso/styles/section.js'
import sectionTitle from '@stiloso/styles/sectionTitle.js'
import IconAdd from '@stiloso/icons/IconAdd.js'
import IconDone from '@stiloso/icons/IconDone.js'
import { useTranslation } from '@multilocale/react/index.js'
import Layout from '../components/Layout.js'
import useLoggedInSession from '../session/useLoggedInSession.js'

export const paths = ['/projects/:projectName/templates/:templateName']

const supportedFormats = ['jpg', 'png', 'webp', 'gif', 'svg']

const NewTemplatePage = () => {
  useLoggedInSession()
  const { projectName, templateName } = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const isNewTemplate = templateName === 'new'
  const { data: project } = useProject(projectName)
  const { data: template } = useTemplate(templateName, {
    enabled: project && !isNewTemplate,
  })
  let [_id, setId] = useState(template?._id || uuid())
  let [name, setName] = useState('')
  const [formats, setFormats] = useState(['jpg'])
  const [sizes, setSizes] = useState([''])

  const changeName = event => {
    let name = event.target.value

    name = name.toLowerCase()
    name = name.replaceAll(' ', '-')

    setName(name)
  }

  const onChangeSize = (size, i) => {
    setSizes(previousSizes => {
      let sizes = [...previousSizes]
      sizes[i] = size

      if (sizes.length > 1 && !sizes[sizes.length - 2]) {
        sizes.pop()
      }

      if (sizes[sizes.length - 1]) {
        sizes[sizes.length] = ''
      }

      return sizes
    })
  }

  const onToggleFormat = format => {
    if (formats.includes(format)) {
      setFormats(previousFormats =>
        previousFormats.filter(previousFormat => previousFormat !== format),
      )
    } else {
      setFormats(formats.concat(format))
    }
  }

  const addOrUpdateTemplate = async event => {
    event.preventDefault()

    try {
      const template = {
        _id,
        name,
        projectId: project._id,
        organizationId: project.organizationId,
        formats,
        sizes,
      }

      if (isNewTemplate) {
        await addTemplate(template)
        navigate(`/projects/${projectName}/templates/${name}`)
      } else {
        await updateTemplate(template)
      }
    } catch (error) {
      // TO DO
    }
  }

  useEffect(() => {
    if (template) {
      setId(template._id)
      setName(template.name)
      setSizes(template.sizes)
      setFormats(template.formats)
    }
  }, [template])

  return (
    <Layout title={t('New template')}>
      <div css={section}>
        <div css={sectionTitle}>{t('New template')}</div>
        <form onSubmit={addOrUpdateTemplate} css={card}>
          <label htmlFor="nameInput" css={label}>
            {t('name')}
          </label>
          <input
            id="nameInput"
            css={input}
            value={name}
            onChange={changeName}
            required
            minLength="1"
            autoFocus
            autoComplete="off"
            data-lpignore="true"
            data-form-type="other"
          />
          <label htmlFor="sizesInput" css={label}>
            {t('Sizes')}
          </label>

          {sizes.map((size, i) => (
            <input
              css={[input, { marginBottom: 8 }]}
              onChange={event => onChangeSize(event.target.value, i)}
              value={size}
              placeholder={t('Add size')}
            />
          ))}

          <label htmlFor="formatsInput" css={label}>
            {t('Formats')}
          </label>
          <div css={{ display: 'flex' }}>
            {supportedFormats.map(format => {
              if (formats.includes(format)) {
                return (
                  <div
                    css={[
                      {
                        display: 'flex',
                        justifyContent: 'center',
                        color: 'white',
                        fill: 'white',
                        background: 'var(--color-primary)',
                        borderRadius: 40,
                        padding: 8,
                        marginRight: 8,
                        minWidth: 96,
                        cursor: 'pointer',
                        userSelect: 'none',
                      },
                      clickable,
                    ]}
                    onClick={_ => onToggleFormat(format)}
                  >
                    <IconDone />
                    {format}
                  </div>
                )
              } else {
                return (
                  <div
                    css={[
                      {
                        display: 'flex',
                        justifyContent: 'center',
                        background: 'white',
                        borderRadius: 40,
                        padding: 8,
                        marginRight: 8,
                        minWidth: 96,
                        cursor: 'pointer',
                        userSelect: 'none',
                      },
                      clickable,
                    ]}
                    onClick={_ => onToggleFormat(format)}
                  >
                    <IconAdd />
                    {format}
                  </div>
                )
              }
            })}
          </div>
          <Button type="submit" css={{ marginTop: 16 }} fullWidth>
            {isNewTemplate ? t('Add template') : t('Save template')}
          </Button>
        </form>
      </div>
    </Layout>
  )
}

export default NewTemplatePage
