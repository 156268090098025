/* Copyright 2013 - 2022 Waiterio LLC */
import { css } from '@emotion/react'
import clickable from '@stiloso/styles/clickable.js'
import singleLine from '@stiloso/styles/singleLine.js'
import unselectable from '@stiloso/styles/unselectable.js'

export default css`
  ${clickable}
  ${singleLine}
  ${unselectable}
  padding: 0px 16px;
  line-height: 48px;
  background-color: white;
`
