/* Copyright 2013 - 2022 Waiterio LLC */
const rotation = `
  @-webkit-keyframes rotation {

    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }

  }

  @keyframes rotation {

    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }

  }
`

export default rotation
