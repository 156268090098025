/* Copyright 2013 - 2022 Waiterio LLC */
import React, { Suspense } from 'react'
import { createRoot } from 'react-dom/client' // eslint-disable-line import/extensions
import { BrowserRouter } from 'react-router-dom'
import ErrorBoundaryGlobal from '@stiloso/components/ErrorBoundaryGlobal.js'
import ScrollToTop from '@stiloso/components/ScrollToTop.js'
import allowSubdomainsCrossOrigin from '@monorepo/shared/allowSubdomainsCrossOrigin.js'
import { initMultilocale } from '@multilocale/react/index.js'
import StageBadge from '@stiloso/components/StageBadge.js'
import ErrorBoundaryGlobalWithSentry from '@stiloso/components/ErrorBoundaryGlobalWithSentry.js'
import ImagelatoProvider from '@imagelato/react/ImagelatoProvider.js'
import Fallback from '@stiloso/components/Fallback.js'
import GlobalStyles from '@imagelato/shared/GlobalStyles.js'
import Routes from './Routes.js'
import LanguageSetter from './session/LanguageSetter.js'
import SessionRehydrator from './session/SessionRehydrator.js'

allowSubdomainsCrossOrigin()
initMultilocale({
  organizationId: 'e395dba6a14d8af4c2d35890',
  project: 'imagelato-app',
})

createRoot(document.getElementById('root')).render(
  <ErrorBoundaryGlobal>
    <BrowserRouter>
      <ScrollToTop />
      <ErrorBoundaryGlobalWithSentry>
        <ImagelatoProvider>
          <SessionRehydrator>
            <Suspense fallback={<Fallback />}>
              <LanguageSetter />
              <GlobalStyles />
              <Routes />
              <StageBadge />
            </Suspense>
          </SessionRehydrator>
        </ImagelatoProvider>
      </ErrorBoundaryGlobalWithSentry>
    </BrowserRouter>
  </ErrorBoundaryGlobal>,
)
