/* Copyright 2013 - 2022 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { jsx } from '@emotion/react'
import spinner from '@stiloso/styles/spinner.js'
import IconUpload from '@stiloso/icons/IconUpload.js'
import { useTranslation } from '@multilocale/react/index.js'

const UploadImageComponent = ({
  isPending,
  onClickUploadInput,
  uploadImage,
  inputId,
  styles,
}) => {
  const { t } = useTranslation()

  return (
    <div css={{ textAlign: 'center' }}>
      {isPending && (
        <div css={{ padding: 32, backgroundColor: 'white' }}>
          <span css={spinner} />
        </div>
      )}

      {!isPending && (
        <>
          <div
            css={[
              {
                padding: 32,
                border: '4px dotted #D8E0ED',
                cursor: 'pointer',
                ':hover, :focus-visible': {
                  color: 'var(--color-primary-light)',
                  fill: 'var(--color-primary-light)',
                  borderColor: 'var(--color-primary-light)',
                },
              },
              styles,
            ]}
            onClick={onClickUploadInput}
          >
            <IconUpload />
            <div>{t('Click to upload')}</div>
          </div>
          <input
            id={inputId}
            type="file"
            accept="image/*"
            capture
            css={{ display: 'none' }}
            tabIndex="-1"
            onChange={uploadImage}
          />
        </>
      )}
    </div>
  )
}

export default UploadImageComponent
