/* Copyright 2013 - 2022 Waiterio LLC */
export default {
  en: 'English',
  es: 'Español',
  zh: '中文',
  af: 'Afrikaans',
  az: 'Azərbaycanca',
  id: 'Bahasa Indonesia',
  ms: 'Bahasa Melayu',
  jv: 'Basa Jawa',
  su: 'Basa Sunda',
  bs: 'Bosanski',
  ca: 'Català',
  ny: 'Chi-Chewa',
  cy: 'Cymraeg',
  da: 'Dansk',
  de: 'Deutsch',
  et: 'Eesti',
  eu: 'Euskara',
  tl: 'Filipino',
  fr: 'Français',
  fy: 'Frysk',
  ga: 'Gaeilge',
  sm: 'Gagana Samoa',
  gl: 'Galego',
  gd: 'Gàidhlig',
  hr: 'Hrvatski jezik',
  ig: 'Igbo',
  is: 'Íslenska',
  it: 'Italiano',
  sw: 'Kiswahili',
  ht: 'Krèyol ayisyen',
  ku: 'Kurdî',
  lv: 'Latviešu',
  lt: 'Lietuvių',
  lb: 'Lëtzebuergesch',
  hu: 'Magyar',
  mg: 'Malagasy',
  my: 'Myanmasa',
  mi: 'Māori',
  nl: 'Nederlands',
  no: 'Norsk',
  pa: 'Panjabi',
  pl: 'Polski',
  pt: 'Português',
  ro: 'Română',
  st: 'Sesotho',
  sq: 'Shqip',
  sk: 'Slovenčina',
  sl: 'Slovenščina',
  so: 'Soomaaliga',
  fi: 'Suomi',
  sv: 'Svenska',
  vi: 'Tiếng Việt',
  tr: 'Türkçe',
  yo: 'Yorùbá',
  sn: 'chiShona',
  xh: 'isiXhosa',
  zu: 'isiZulu',
  cs: 'Čeština',
  el: 'ελληνικά',
  uz: 'Ўзбек',
  be: 'Беларуская',
  bg: 'Български',
  ky: 'Кыргызча',
  mk: 'Македонски',
  mn: 'Монгол',
  ru: 'Русский',
  sr: 'Српски',
  tg: 'Тоҷикӣ',
  uk: 'Українська',
  kk: 'Қазақша',
  hy: 'Հայերեն',
  yi: 'ייִדיש',
  he: 'עברית',
  ur: 'اردو',
  ar: 'العربية',
  fa: 'فارسی',
  ha: 'هَوُسَ',
  ps: 'پښتو',
  ne: 'नेपाली',
  mr: 'मराठ',
  sd: 'सिनधि',
  hi: 'हिन्दी',
  bn: 'বাংলা',
  gu: 'ગુજરાતી',
  ta: 'தமிழ்',
  te: 'తెలుగ',
  kn: 'ಕನ್ನಡ',
  ml: 'മലയാളം',
  si: 'සිංහල',
  th: 'ไทย',
  lo: 'ລາວ',
  ka: 'ქართული',
  am: 'አማርኛ',
  km: 'ភាសាខ្មែរ',
  ja: '日本語',
  ko: '한국어',
}
