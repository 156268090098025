/* Copyright 2013 - 2022 Waiterio LLC */
import { useQuery } from '@tanstack/react-query'
import getTemplate from '@imagelato/client/getTemplate.js'

export default function useTemplate(templateIdOrName, options) {
  return useQuery({
    queryKey: ['templates', templateIdOrName],
    queryFn: () => getTemplate(templateIdOrName),
    enabled: !!templateIdOrName,
    ...options,
  })
}
