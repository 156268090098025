/* Copyright 2013 - 2022 Waiterio LLC */
import { useQuery } from '@tanstack/react-query'
import getUser from '@imagelato/client/getUser.js'

export default function useUser(userId, options) {
  return useQuery({
    queryKey: ['users', userId],
    queryFn: () => getUser(userId),
    ...options,
  })
}
