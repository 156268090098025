/* Copyright 2013 - 2022 Waiterio LLC */
import deepmerge from 'deepmerge'
import fetch from 'isomorphic-fetch'

export default function fetch_(arg1, arg2) {
  let request = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  }

  if (typeof arg1 === 'string' || arg1 instanceof String) {
    request.url = arg1

    if (arg2 && typeof arg2 === 'object' && arg2.constructor === Object) {
      request = deepmerge(request, arg2)
    }
  } else if (arg1 && typeof arg1 === 'object' && arg1.constructor === Object) {
    request = deepmerge(request, arg1)
  }

  if (typeof request.body === 'object') {
    request.body = JSON.stringify(request.body)
  }

  return fetch(request.url, request).then(response => {
    const contentType = response.headers.get('content-type')

    if (!response.ok) {
      if (contentType && contentType.indexOf('application/json') !== -1) {
        return response.json().then(error => {
          throw error
        })
      } else {
        return response.text().then(message => {
          const error = new Error(message)
          error.status = response.status

          throw error
        })
      }
    } else {
      return response.json()
    }
  })
}
