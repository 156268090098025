/* Copyright 2013 - 2022 Waiterio LLC */
/** @jsx jsx */
import React, { useState } from 'react'
import { css, jsx } from '@emotion/react'
import { useNavigate } from 'react-router-dom'
import addProject from '@imagelato/client/addProject.js'
import card from '@stiloso/styles/card.js'
import label from '@stiloso/styles/label.js'
import input from '@stiloso/styles/input.js'
import Button from '@stiloso/components/Button.js'
import section from '@stiloso/styles/section.js'
import sectionTitle from '@stiloso/styles/sectionTitle.js'
import { useTranslation } from '@multilocale/react/index.js'
import Layout from '../components/Layout.js'
import useLoggedInSession from '../session/useLoggedInSession.js'

export const paths = ['/projects/new']

const NewProjectPage = () => {
  const navigate = useNavigate()
  useLoggedInSession()
  const { t } = useTranslation()
  let [name, setName] = useState('')

  const changeName = event => {
    let name = event.target.value

    name = name.toLowerCase()
    name = name.replaceAll(' ', '-')

    setName(name)
  }

  const createProject = async event => {
    event.preventDefault()

    try {
      const project = {
        name: name.replace(/^-+|-+$/g, ''),
      }

      await addProject(project)

      navigate(`/projects/${name}`)
    } catch (error) {
      // TO DO
    }
  }

  return (
    <Layout>
      <div css={section}>
        <div css={sectionTitle}>{t('New website')}</div>
        <form onSubmit={createProject} css={card}>
          <label htmlFor="nameInput" css={label}>
            {t('name')}
          </label>
          <input
            id="nameInput"
            css={input}
            value={name}
            onChange={changeName}
            required
            minLength="1"
            autoFocus
            autoComplete="off"
            data-lpignore="true"
            data-form-type="other"
          />
          <Button type="submit" css={{ marginTop: 16 }} fullWidth>
            {t('Add project')}
          </Button>
        </form>
      </div>
    </Layout>
  )
}

export default NewProjectPage
