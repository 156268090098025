/* Copyright 2013 - 2022 Waiterio LLC */
import { useQuery } from '@tanstack/react-query'
import getUsers from '@imagelato/client/getUsers.js'

export default function useUsers() {
  return useQuery({
    queryKey: ['users'],
    queryFn: () => getUsers(),
  })
}
