/* Copyright 2013 - 2022 Waiterio LLC */
/** @jsx jsx */
import React, { useState } from 'react'
import { useTranslation } from '@multilocale/react/index.js'
import { jsx, css } from '@emotion/react'
import label from '@stiloso/styles/label.js'

const arrowDown = css`
  border-bottom: 3px solid rgba(0, 0, 0, 0);
  border-left: 6px solid rgba(0, 0, 0, 0);
  border-right: 6px solid rgba(0, 0, 0, 0);
  border-top: 6px solid #6a768a;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  position: absolute;
  bottom: 18px;
  right: 14px;
`

const arrowUp = css`
  border-top: 3px solid rgba(0, 0, 0, 0);
  border-left: 6px solid rgba(0, 0, 0, 0);
  border-right: 6px solid rgba(0, 0, 0, 0);
  border-bottom: 6px solid var(--color-primary-lightest);
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  position: absolute;
  bottom: 22px;
  right: 14px;
`

const Dropdown = ({
  name,
  labels,
  values,
  onSelect,
  currentValue,
  transparent = true,
  labelStyles,
}) => {
  const { t } = useTranslation()
  const [isVisible, setVisible] = useState(false)

  const indexCurrentValue = values.findIndex(
    element => element === currentValue,
  )
  const currentLabel =
    labels && labels.length > indexCurrentValue
      ? labels[indexCurrentValue]
      : currentValue

  const toggleDropdown = () => {
    setVisible(prevState => !prevState)
  }

  const onSelectValue = value => {
    onSelect(value)
    toggleDropdown()
  }

  return (
    <div css={{ position: 'relative', width: '100%' }}>
      <div
        css={[label, { display: 'inline-block' }, labelStyles]}
        onClick={toggleDropdown}
      >
        {t(name)}
      </div>

      <button
        type="button"
        css={{
          width: '100%',
          boxShadow: isVisible
            ? 'inset 0px 0px 0px 2px var(--color-primary-lightest)'
            : 'inset 0px 0px 0px 1px #d8e0ed',
          borderRadius: 4,
          padding: 12,
          height: 50,
          color: isVisible ? 'var(--color-primary-lightest)' : '#6a768a',
          cursor: 'pointer',
          backgroundColor: !transparent ? 'white' : 'transparent',
          textAlign: 'left',
          border: 0,
        }}
        onClick={toggleDropdown}
      >
        {t(currentLabel)}
        <span css={isVisible ? arrowUp : arrowDown} />
      </button>
      {isVisible && (
        <div
          css={{
            position: 'absolute',
            top: '100%',
            left: 0,
            maxHeight: 140,
            width: '100%',
            zIndex: 99999,
            overflowY: 'auto',
            backgroundColor: transparent ? 'var(--color-background)' : 'white',
            boxShadow: 'inset 0px 0px 0px 1px #D8E0ED',
            borderTop: 0,
            borderRadius: 4,
          }}
        >
          {values?.map(
            (value, index) =>
              value !== currentValue && (
                <button
                  key={value}
                  onClick={() => onSelectValue(value)}
                  type="button"
                  css={{
                    padding: 12,
                    background: 'transparent',
                    width: '100%',
                    border: 0,
                    cursor: 'pointer',
                    ':hover, :focus': {
                      backgroundColor: 'var(--color-primary-lightest)',
                      color: 'white',
                    },
                    textAlign: 'left',
                  }}
                >
                  {t(labels[index])}
                </button>
              ),
          )}
        </div>
      )}
    </div>
  )
}

export default Dropdown
