/* Copyright 2013 - 2022 Waiterio LLC */
/** @jsx jsx */
import React, { useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useTranslation } from '@multilocale/react/index.js'
import { css, jsx } from '@emotion/react'
import useRoles from '@imagelato/react/useRoles.js'
import useUser from '@imagelato/react/useUser.js'
import useAddRoleMutation from '@imagelato/react/useAddRoleMutation.js'
import useDeleteRoleMutation from '@imagelato/react/useDeleteRoleMutation.js'
import list from '@stiloso/styles/list.js'
import listItem from '@stiloso/styles/listItem.js'

import IconDone from '@stiloso/icons/IconDone.js'
import DeleteDialog from '@stiloso/components/DeleteDialog.js'
import ErrorDialog from '@stiloso/components/ErrorDialog.js'
import Layout from '../components/Layout.js'

import useLoggedInSession from '../session/useLoggedInSession.js'

export const paths = ['/team/:userId']

const ROLES = ['admin', 'developer', 'translator']

const TeamMemberPage = () => {
  useLoggedInSession()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { userId } = useParams()
  const { data: user } = useUser(userId)
  const { data: roles } = useRoles()
  const [deleteConfirmationDialog, setDeleteConfirmationDialog] =
    useState(false)

  const userRoles = roles
    .filter(role => role.userId === userId)
    .map(role => role.type)

  const addRoleMutation = useAddRoleMutation()
  const deleteRoleMutation = useDeleteRoleMutation()

  const addRole = roleType => {
    addRoleMutation.mutate({
      userId,
      type: roleType,
    })
  }

  const showDeleteConfirmationDialog = userRole => {
    setDeleteConfirmationDialog({ userRole })
  }

  const hideDeleteConfirmationDialog = () => {
    setDeleteConfirmationDialog(false)
  }

  const deleteRole = roleType => {
    const userRole = roles.find(
      role => role.userId === userId && role.type === roleType,
    )
    if (userRoles.length === 1) {
      showDeleteConfirmationDialog(userRole)
    } else {
      deleteRoleMutation.mutate(userRole._id)
    }
  }

  const removeTeamMember = () => {
    const { userRole } = deleteConfirmationDialog
    deleteRoleMutation.mutate(userRole._id, {
      onSuccess: () => {
        hideDeleteConfirmationDialog()
        navigate('/team')
      },
    })
  }

  return (
    <Layout>
      <div
        css={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          maxWidth: 512,
          margin: '32px auto',
          padding: 16,
        }}
      >
        <div
          css={{
            fontWeight: 'bold',
            marginBottom: 16,
            color: '#273854',
          }}
        >
          {t('Details')}
        </div>
        <div css={{ padding: 16, backgroundColor: 'white', borderRadius: 4 }}>
          <div
            css={{
              color: '#273854',
              fontWeight: 'bold',
              fontSize: 14,
              marginBottom: 8,
              textTransform: 'capitalize',
            }}
          >
            {t('email')}
          </div>
          <div css={{ color: '#6A768A' }}>{user.email}</div>
        </div>
      </div>

      <div
        css={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          maxWidth: 512,
          margin: '32px auto',
          padding: 16,
        }}
      >
        <div
          css={{
            fontWeight: 'bold',
            color: '#273854',
          }}
        >
          {t('Roles')}
        </div>
        <div css={list}>
          {ROLES.map(role => (
            <div key={role} css={[listItem]}>
              {userRoles.includes(role) ? (
                <div
                  css={{
                    display: 'flex',
                    flex: 1,
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                  onClick={() => deleteRole(role)}
                >
                  <div>{t(role)}</div>
                  <IconDone />
                </div>
              ) : (
                <div onClick={() => addRole(role)}>{t(role)}</div>
              )}
            </div>
          ))}
        </div>
        {addRoleMutation.error && (
          <ErrorDialog
            title={t('Error')}
            message={addRoleMutation.error.message}
            close={addRoleMutation.reset}
          />
        )}
        {deleteRoleMutation.error && (
          <ErrorDialog
            title={t('Error')}
            message={deleteRoleMutation.error.message}
            close={deleteRoleMutation.reset}
          />
        )}
      </div>

      {deleteConfirmationDialog && (
        <DeleteDialog
          title={t('Remove team member')}
          message={t('Do you want to remove {{user}}?').replace(
            '{{user}}',
            user.email,
          )}
          onDelete={removeTeamMember}
          close={hideDeleteConfirmationDialog}
          loading={deleteRoleMutation.isLoading}
        />
      )}
    </Layout>
  )
}

export default TeamMemberPage
