/* Copyright 2013 - 2022 Waiterio LLC */
/** @jsx jsx */
import React, { useState, useRef, useEffect } from 'react'
import { jsx, css } from '@emotion/react'

import label from '@stiloso/styles/label.js'
import input from '@stiloso/styles/input.js'

import { useTranslation } from '@multilocale/react/index.js'

const arrowDown = css`
  border-bottom: 3px solid rgba(0, 0, 0, 0);
  border-left: 6px solid rgba(0, 0, 0, 0);
  border-right: 6px solid rgba(0, 0, 0, 0);
  border-top: 6px solid #6a768a;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  position: absolute;
  bottom: 18px;
  right: 14px;
`

const arrowUp = css`
  border-top: 3px solid rgba(0, 0, 0, 0);
  border-left: 6px solid rgba(0, 0, 0, 0);
  border-right: 6px solid rgba(0, 0, 0, 0);
  border-bottom: 6px solid var(--color-primary-lightest);
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  position: absolute;
  bottom: 22px;
  right: 14px;
`

const LanguageAutocomplete = ({
  languages,
  selectedLanguage,
  onSelectLanguage,
  inputLabel,
  autoFocused,
}) => {
  const { t } = useTranslation()
  const languageAutocompleteRef = useRef()
  const [searchLanguage, setSearchLanguage] = useState('')
  const [languageAutocomplete, setLanguageAutocomplete] = useState(false)

  const currentLanguage = languages.find(
    language => language.code === selectedLanguage,
  )

  const filteredLanguages = languages.filter(language =>
    language.name.match(new RegExp(searchLanguage, 'gi')),
  )

  const onSearchLanguage = event => {
    const { value } = event.target
    setSearchLanguage(value)
  }

  const onKeyDown = event => {
    if (event.key === 'Escape') {
      setLanguageAutocomplete(false)
    }
  }

  const onChangeLanguage = languageCode => {
    onSelectLanguage(languageCode)
    setSearchLanguage('')
    setLanguageAutocomplete(false)
  }

  useEffect(() => {
    function onClickOutside(event) {
      if (
        languageAutocompleteRef.current &&
        !languageAutocompleteRef.current.contains(event.target)
      ) {
        setSearchLanguage('')
        setLanguageAutocomplete(false)
      }
    }
    document.addEventListener('mousedown', onClickOutside)

    return function cleanup() {
      document.removeEventListener('mousedown', onClickOutside)
    }
  }, [])

  return (
    <div ref={languageAutocompleteRef} css={{ position: 'relative' }}>
      <label htmlFor="languageInput" css={label}>
        {t(inputLabel) || t('Language')}
      </label>
      <input
        id="languageInput"
        css={[
          input,
          !languageAutocomplete && {
            '::placeholder': { color: '#6A768A' },
          },
        ]}
        type="text"
        placeholder={
          currentLanguage ? currentLanguage.name : t('Select language')
        }
        value={searchLanguage}
        onFocus={() => setLanguageAutocomplete(true)}
        onChange={event => {
          onSearchLanguage(event)
          setLanguageAutocomplete(true)
        }}
        required={!selectedLanguage && true}
        onKeyDown={onKeyDown}
        autoComplete="off"
        autoFocus={autoFocused}
        data-hj-allow
      />
      <label
        htmlFor="languageInput"
        css={languageAutocomplete ? arrowUp : arrowDown}
      />
      {languageAutocomplete && (
        <div
          css={{
            position: 'absolute',
            zIndex: 10000,
            maxHeight: 208,
            width: '100%',
            overflowY: 'auto',
            backgroundColor: 'var(--color-background)',
            border: '1px solid #D8E0ED',
            borderTop: 0,
          }}
        >
          {filteredLanguages &&
            filteredLanguages.map(language => (
              <div
                key={language.code}
                onClick={() => onChangeLanguage(language.code)}
                css={{
                  padding: 10,
                  ':hover, :focus-visible': {
                    backgroundColor: 'var(--color-primary-lightest)',
                    color: 'white',
                    cursor: 'pointer',
                  },
                }}
              >
                {language.name}
              </div>
            ))}
        </div>
      )}
    </div>
  )
}

export default LanguageAutocomplete
