/* Copyright 2013 - 2022 Waiterio LLC */
import getAccessToken from './getAccessToken.js'

export default function getAccessTokenPayload() {
  let payload

  let accessToken = getAccessToken()

  if (accessToken) {
    try {
      payload = JSON.parse(atob(accessToken.split('.')[1]))
    } catch (error) {
      console.error(error)
    }
  }

  return payload
}
