/* Copyright 2013 - 2022 Waiterio LLC */
import get from './get.js'

export default function getUsers() {
  let url = 'users'

  return get({ url }).catch(error => {
    throw new Error(`Couldn't get users \n${error}`)
  })
}
