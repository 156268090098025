/* Copyright 2013 - 2022 Waiterio LLC */
/** @jsx jsx */
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { jsx, css } from '@emotion/react'
import addImage from '@imagelato/client/addImage.js'
import useProject from '@imagelato/react/useProject.js'
import useTemplates from '@imagelato/react/useTemplates.js'
import readFile from '@monorepo/shared/readFile.js'
import IconAdd from '@stiloso/icons/IconAdd.js'
import IconDone from '@stiloso/icons/IconDone.js'
import { useTranslation } from '@multilocale/react/index.js'
import Button from '@stiloso/components/Button.js'
import Dropdown from '@stiloso/components/Dropdown.js'
import UploadImageComponent from '@stiloso/components/UploadImage.js'
import section from '@stiloso/styles/section.js'
import sectionTitle from '@stiloso/styles/sectionTitle.js'
import Layout from '../components/Layout.js'
import useLoggedInSession from '../session/useLoggedInSession.js'

const input = css`
  label: input;

  width: 100%;
  box-shadow: inset 0px 0px 0px 1px #d8e0ed;
  border-radius: 4px;
  padding: 12px;
  color: #6a768a;
  background: white;
  line-height: 24px;

  ::placeholder {
    // TODO color
  }

  @media (hover: hover) {
    &:not(.disabled):hover {
      box-shadow: inset 0px 0px 0px 2px var(--color-primary-lightest);
      cursor: text;
    }
  }

  &:not(.disabled):active {
    box-shadow: inset 0px 0px 0px 2px var(--color-primary-lightest);
    cursor: text;
  }
`

const label = css`
  font-size: 14px;
  font-weight: bold;
  text-transform: capitalize;
  color: var(--color-base);
  line-height: 48px;
`

const supportedFormats = ['jpg', 'png', 'webp', 'gif', 'svg']

export const paths = ['/projects/:projectName/images/new']

const NewImagePage = () => {
  useLoggedInSession()
  const { projectName } = useParams()
  const { data: project } = useProject(projectName)
  const { t } = useTranslation()
  const { data: templates } = useTemplates({ projectId: project._id })
  const templateNames = [t('none'), ...templates.map(template => template.name)]
  const [selectedTemplate, setSelectedTemplate] = useState(templates[0])
  const [error, setError] = useState(false)
  const [slug, setSlug] = useState(
    selectedTemplate ? `${selectedTemplate.name}-` : '',
  )
  const [formats, setFormats] = useState(selectedTemplate?.formats || ['jpg'])
  const [sizes, setSizes] = useState(selectedTemplate?.sizes || [''])
  const [loading, setLoading] = useState(false)
  const [response, setResponse] = useState(false)

  function changeSlug(event) {
    setSlug(event.target.value)
  }

  const onChangeSize = (size, i) => {
    setSizes(previousSizes => {
      let sizes = [...previousSizes]
      sizes[i] = size

      if (sizes.length > 1 && !sizes[sizes.length - 2]) {
        sizes.pop()
      }

      if (sizes[sizes.length - 1]) {
        sizes[sizes.length] = ''
      }

      return sizes
    })
  }

  const onToggleFormat = format => {
    if (formats.includes(format)) {
      setFormats(previousFormats =>
        previousFormats.filter(previousFormat => previousFormat !== format),
      )
    } else if (format === 'svg') {
      setFormats([format])
      setSizes([''])
    } else {
      setFormats(formats.concat(format))
    }
  }

  const reset = () => {
    setResponse(null)
    setSlug(selectedTemplate ? `${selectedTemplate.name}-` : '')
    setFormats(selectedTemplate?.formats || ['jpg'])
    setSizes(selectedTemplate?.sizes || [''])
  }

  const onClickUploadImage = () => {
    document.getElementById('uploadImageInput').click()
  }

  const uploadImage = event => {
    setError(null)
    const file = event.target.files[0]
    let extension

    if (file.name && file.name.indexOf('.' > -1)) {
      const splits = file.name.split('.')
      extension = splits[splits.length - 1]
    } else if (file.type && file.type.indexOf('/') > -1) {
      const splits = file.type.split('/')
      extension = splits[splits.length - 1]
    }

    if (
      extension === 'jpg' ||
      extension === 'jpeg' ||
      extension === 'png' ||
      extension === 'webp' ||
      extension === 'gif' ||
      extension === 'svg'
    ) {
      readFile(file)
        .then(file => {
          setLoading(true)

          return addImage({
            slug,
            file,
            formats,
            sizes,
            projectId: project._id,
            template: selectedTemplate?.name,
          })
        })
        .then(response => {
          setLoading(false)
          setResponse(response)
        })
        .catch(error => {
          setError(error)
        })
    } else {
      setError(
        'Images should be in .jpg or .png or .webp or .gif or .svg format',
      )
    }
  }

  const onChangeTemplate = templateName => {
    if (templateName === 'none') {
      setSelectedTemplate(null)
      setSizes([''])
      setFormats(['jpg'])
      setSlug('')
    } else {
      const template = templates.find(
        template => templateName === template.name,
      )
      setSelectedTemplate(template)
      setSizes(template.sizes)
      setFormats(template.formats)
      setSlug(`${template.name}-`)
    }
  }

  return (
    <Layout>
      <div css={section}>
        <div css={sectionTitle}>{t('New image')}</div>
      </div>
      {error && <div>{error}</div>}
      {loading && <div className="loader" />}
      {response && (
        <div css={{ margin: 16 }}>
          <div css={{ display: 'flex', flexDirection: 'column' }}>
            <div>{response.url}</div>
            {response.variants?.map(variant => (
              <div key={variant}>{variant}</div>
            ))}
          </div>
          <Button onClick={reset} css={{ marginTop: 16 }}>
            {t('Upload another')}
          </Button>
        </div>
      )}
      {!loading && !response && (
        <div css={section}>
          <div css={{ margin: 'auto' }}>
            {templates.length > 0 && (
              <div css={{ marginBottom: 16 }}>
                <Dropdown
                  name={t('Templates')}
                  values={templateNames}
                  labels={templateNames}
                  currentValue={
                    selectedTemplate ? selectedTemplate.name : 'none'
                  }
                  onSelect={onChangeTemplate}
                  transparent={false}
                />
              </div>
            )}

            <div>
              <label htmlFor="slug" css={label}>
                Slug
              </label>
              <input
                id="slug"
                css={input}
                onChange={changeSlug}
                placeholder="food-truck-cover"
                value={slug}
              />
            </div>

            {!formats.includes('svg') && (
              <div css={{ marginTop: 16 }}>
                <label htmlFor="sizes" css={label}>
                  {t('Sizes')}
                </label>
                {sizes.map((size, i) => (
                  <input
                    css={[input, { marginBottom: 8 }]}
                    onChange={event => onChangeSize(event.target.value, i)}
                    value={size}
                    placeholder={t('Add size')}
                  />
                ))}
              </div>
            )}

            <div css={{ marginTop: 16 }}>
              <label htmlFor="formats" css={label}>
                {t('Formats')}
              </label>
              <div css={{ display: 'flex' }}>
                {supportedFormats.map(format => {
                  if (formats.includes(format)) {
                    return (
                      <div
                        key={format}
                        css={{
                          display: 'flex',
                          justifyContent: 'center',
                          color: 'white',
                          fill: 'white',
                          background: 'var(--color-primary)',
                          borderRadius: 40,
                          padding: 8,
                          marginRight: 8,
                          minWidth: 96,
                          cursor: 'pointer',
                          userSelect: 'none',
                        }}
                        onClick={_ => onToggleFormat(format)}
                      >
                        <IconDone />
                        {format}
                      </div>
                    )
                  } else {
                    return (
                      <div
                        key={format}
                        css={{
                          display: 'flex',
                          justifyContent: 'center',
                          background: 'white',
                          borderRadius: 40,
                          padding: 8,
                          marginRight: 8,
                          minWidth: 96,
                          cursor: 'pointer',
                          userSelect: 'none',
                        }}
                        onClick={_ => onToggleFormat(format)}
                      >
                        <IconAdd />
                        {format}
                      </div>
                    )
                  }
                })}
              </div>
            </div>

            <div css={{ marginTop: 16 }}>
              <UploadImageComponent
                inputId="uploadImageInput"
                onClickUploadInput={onClickUploadImage}
                uploadImage={uploadImage}
                isPending={loading}
              />
            </div>
          </div>
        </div>
      )}
    </Layout>
  )
}

export default NewImagePage
