/* Copyright 2013 - 2022 Waiterio LLC */
import React from 'react'

const IconUpload = props => {
  const { className, width = 24, height = 24, onClick, style } = props
  const dataTestId = props['data-testid'] || 'icon-upload'

  return (
    <svg
      className={className}
      style={style}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      onClick={onClick}
      data-testid={dataTestId}
    >
      <path d="M9 16.008h6v-6h4.008l-7.008-7.008-7.008 7.008h4.008zM4.992 18h14.016v1.992h-14.016z" />
    </svg>
  )
}

export default IconUpload
